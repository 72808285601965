import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const KoerperContouringMitInjektionslipolyse = ({
	data,
	location,
	...props
}) => {
	return (
		<Layout
			location={location}
			title="Körper-Contouring mit Injektionslipolyse"
			noSlider
			{...props}
		>
			<p>
				Überflüssiges Fettgewebe mittels einer Spritze aufzulösen- eine
				begeisternde Technik.
			</p>
			<p>Hunderttausendfach bewährt seit 2003. </p>
			<p>Stetig verbessert. </p>
			<p>
				Der Wirkstoff ist ein Naturpräparat aus der Sojabohne und wird
				zusammen mit anderen Wirkstoffen direkt in das zu behandelnde
				Fettgewebe durch Kanülen injiziert. Die Substanzmischung wird
				von einem Apotheker hergestellt.
			</p>
			<p>
				Die Therapie ist wegen der hohen Kosten einer
				Medikamentenzulassung als Therapie nicht zugelassen.
			</p>
			<p>
				Es handelt sich seit vielen Jahren um eine Off-Label Therapie,
				wie viele andere bewährten Therapien auch.
			</p>
			<p>
				Fast sämtliche Regionen des Körpers lassen sich behandeln und
				die Fettdepots reduzieren. Man behandelt 2-4 mal im Abstand von
				8 Wochen. Mit leichten Schwellungen , Rötungen und Verhärtungen
				über 2-3 Tage ist im behandelten Areal zu rechnen. Nach diesen
				Tagen ist der Bereich wieder beschwerdefrei.
			</p>

			<Questions
				title="Körper-Contouring mit Injektionslipolyse"
				prefix="um"
			/>
		</Layout>
	);
};

export default KoerperContouringMitInjektionslipolyse;
